import React from 'react';

const ShowModal = ({ isOpen, onClose, title, message, confirmText, onConfirm, downloadLink }) => {
    return isOpen ? (
        <>
            <div
                className="modal fade show"
                style={{ display: 'block' }}
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {title}
                            </h5>
                        </div>
                        <div className="modal-body" dangerouslySetInnerHTML={{ __html: message || '' }}>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={onClose}>
                                Close
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    ) : null;
};

export default ShowModal;