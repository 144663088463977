import { fetchAuthSession } from 'aws-amplify/auth';

const handleSelect = (event, setDatabaseName) => {
    setDatabaseName(event.target.textContent);
};

const handleButtonClick = async (setDownloadLink, setDownloadModal, databaseName, s3_purl_api_url) => {
    if (databaseName) {
        try {
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await fetch(`${s3_purl_api_url}?filename=${databaseName}.zip`, {
                headers: {
                    Authorization: `Bearer ${idToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setDownloadLink(data.pre_signed_url);
            setDownloadModal(true);
        } catch (error) {
            console.error('Error generating download link:', error);
        }
    } else {
        alert('Please select a Database first.');
    }
};

const handleClose = (setDownloadModal) =>
    setDownloadModal(false);

export { handleSelect, handleButtonClick, handleClose };
