// Whitelist IP
import { fetchAuthSession } from 'aws-amplify/auth';

const whitelistIpApiCall = async (event, setApiResponse, setinputValueIP, setShowModal, api_url, ipversion) => {
    event.preventDefault();
    const inputString = event.target.inputString.value;

    try {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};

        const response = await fetch(`${api_url}?ip${ipversion}str=${inputString}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        });

        const data = await response.json();
        setApiResponse(data["Message"]);
    } catch (error) {
        console.error('Error during API call', error);
        setApiResponse('Oops...You had an error during updating the whitelist. Double check your IP format or talk to Harry.');
    }

    setinputValueIP('');
    setShowModal(true);
};

export { whitelistIpApiCall };
