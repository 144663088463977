import React from 'react';

const ConfirmModal = ({ isOpen, taskNum, svcName, onClose, onConfirm }) => {
    return isOpen ? (
        <div className="modal-backdrop-confirm show" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header bg-light">
                        <h5 className="modal-title">Confirm</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <p>
                            Are you sure you want to {taskNum === 1 ? 'turn on' : 'shut down'} the service:{' '}
                            <strong>
                                <u>{svcName}</u>
                            </strong>
                            ?
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>
                            Cancel
                        </button>
                        <button type="button" className="btn btn-primary" onClick={onConfirm}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default ConfirmModal;