// Show IP
const showMyIP = async (setApiResponse) => {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setApiResponse(data.ip);
    } catch (error) {
        console.error('Error fetching the IP address:', error);
    }

};


export { showMyIP };
