import { withAuthenticator } from '@aws-amplify/ui-react';
import { Header } from "./Header";
import { Footer } from "./Footer";
import { SignInHeader } from "./SignInHeader";
import { SignInFooter } from "./SignInFooter";
import { Home } from "./Home";
import "./styles.css";


function App({ signOut, user }) {
  return (
    <>
      <Home signOut={signOut} user={user} />
    </>
  );
}

export default withAuthenticator(App, {
  hideSignUp: true,
  components: {
    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    Footer
  }
});