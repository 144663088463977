import { Image, useTheme } from "@aws-amplify/ui-react";

export function Header() {
    const { tokens } = useTheme();

    return (
        <Image
            alt="logo"
            src="https://www.daveclarkdesign.com/theme/images/dave-clark-logo-black.svg"
            padding={tokens.space.medium}
        />
    );
}

