import React, { useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { whitelistIpApiCall } from './components/functions/Whitelist';
import { handleSelect, handleButtonClick, handleClose } from './components/functions/DownloadDatabase';
import { toggleService, handleTurnOn, handleShutdown } from './components/functions/ServiceStatus';
import { showMyIP } from './components/functions/ShowIP';
import ConfirmModal from './components/modals/ConfirmModal';
import ShowModal from './components/modals/ShowModal';

export function Home({ signOut, user }) {
    const [apiResponse, setApiResponse] = useState('');

    // Whitelist IP Function - Projects
    const [activeTab, setActiveTab] = useState('tab2');
    const [whitelistShowModal, setWhitelistShowModal] = useState(false);

    const [inputValueLiTestIPv4, setInputValueLiTestIPv4] = useState('');
    const [inputValueLiTestIPv6, setInputValueLiTestIPv6] = useState('');
    const li_test_url = 'https://vczw3k8rb8.execute-api.ap-southeast-1.amazonaws.com/v1/allowipaddress';
    const [inputValueDCClientIPv4, setInputValueDCClientIPv4] = useState('');
    const [inputValueDCClientIPv6, setInputValueDCClientIPv6] = useState('');
    const dc_clients_url = 'https://e6bum2rdh5.execute-api.us-west-2.amazonaws.com/v1/allowipaddress';
    const [inputValueFoxtelTestIPv4, setInputValueFoxtelTestIPv4] = useState('');
    const [inputValueFoxtelTestIPv6, setInputValueFoxtelTestIPv6] = useState('');
    const foxtel_test_url = 'https://a37wlloar1.execute-api.ap-southeast-2.amazonaws.com/v1/allowipaddress';
    const [inputValueFBTestIPv4, setInputValueFBTestIPv4] = useState('');
    const [inputValueFBTestIPv6, setInputValueFBTestIPv6] = useState('');
    const fb_test_url = 'https://p1ft8m5ty4.execute-api.ap-southeast-2.amazonaws.com/v1/allowipaddress';
    const [inputValueFBUatIPv4, setInputValueFBUatIPv4] = useState('');
    const [inputValueFBUatIPv6, setInputValueFBUatIPv6] = useState('');
    const fb_uat_url = 'https://iid6wi9utb.execute-api.ap-southeast-2.amazonaws.com/v1/allowipaddress';
    const [inputValueVistraObDevIPv4, setInputValueVistraObDevIPv4] = useState('');
    const [inputValueVistraObDevIPv6, setInputValueVistraObDevIPv6] = useState('');
    const vistra_ob_dev_url = 'https://354p0mqpvc.execute-api.eu-west-1.amazonaws.com/v1/allowipaddress';
    const [inputValueVistraObUatIPv4, setInputValueVistraObUatIPv4] = useState('');
    const [inputValueVistraObUatIPv6, setInputValueVistraObUatIPv6] = useState('');
    const vistra_ob_uat_url = 'https://816dwlotk5.execute-api.eu-west-1.amazonaws.com/v1/allowipaddress';

    // Download Database Function - Projects
    const [databaseName, setDatabaseName] = useState('');
    const [downloadLink, setDownloadLink] = useState('');
    const [s3DownloadModal, setS3DownloadModal] = useState(false);
    const dc_clients_s3_purl_api_url = 'https://e6bum2rdh5.execute-api.us-west-2.amazonaws.com/v1/s3/purl'
    const databases = [
        'dc_lake_lyell_test',
        'dc_eden_investment_sg_test',
        'dc_website_cms_test',
        'dcd_jti_cms_db_test',
    ];

    // State for each project's checkbox
    const [svcConfModal, setSvcConfModal] = useState(false);

    const [isCheckedDCWebsite, setIsCheckedDCWebsite] = useState(false);
    const [isCheckedJTICMS, setIsCheckedJTICMS] = useState(false);
    const [isCheckedLakeLyell, setIsCheckedLakeLyell] = useState(false);
    const [isCheckedEdenInvestments, setIsCheckedEdenInvestments] = useState(false);
    const dc_clients_ecs_api_url = 'https://e6bum2rdh5.execute-api.us-west-2.amazonaws.com/v1/ecsservice'
    const [taskNum, setTaskNum] = useState(1); // Task number for API request
    const [svcName, setSvcName] = useState(''); // Current svcName for API request

    const projects = [
        { name: 'DC Website', svcName: 'daveclark-test', isChecked: isCheckedDCWebsite, setIsChecked: setIsCheckedDCWebsite },
        { name: 'JTI CMS', svcName: 'jti-cms-test', isChecked: isCheckedJTICMS, setIsChecked: setIsCheckedJTICMS },
        { name: 'Lake Lyell', svcName: 'lake-lyell-test', isChecked: isCheckedLakeLyell, setIsChecked: setIsCheckedLakeLyell },
        { name: 'Eden Investments', svcName: 'eden-investments-sg-test', isChecked: isCheckedEdenInvestments, setIsChecked: setIsCheckedEdenInvestments },
    ];

    useEffect(() => {
        const syncStatus = async (svcName, setIsChecked) => {
            try {
                const { idToken } = (await fetchAuthSession()).tokens ?? {};
                const response = await fetch(`${dc_clients_ecs_api_url}?svcname=${svcName}`, {
                    headers: {
                        Authorization: `Bearer ${idToken}`
                    }
                });
                const data = await response.json();
                setIsChecked(data.running_count > 0);
            } catch (error) {
                console.error(`Error fetching status for ${svcName}:`, error);
            }
        };

        if (activeTab === 'tab2') {  // Refresh Status at DC Clients Tab
            projects.forEach(project => {
                syncStatus(project.svcName, project.setIsChecked);
            });
        }
    });

    // Show My IP address
    const [myIPShowModal, setMyIPShowModal] = useState(false);

    const handleShowIP = async () => {
        await showMyIP(setApiResponse);
        setMyIPShowModal(true);
    };

    return (
        <main>

            <div className="container pt-4 pb-4 ">
                <div className="row align-items-center">
                    <div className="col">
                        <h1>DC Tech Admin Portal</h1>
                    </div>
                    <div className="col text-end">
                        <div className="d-inline me-2 user-info">
                            <i className="fas fa-user"></i>
                            <span className="user-name">{user.username}</span>
                        </div>
                        <button className="btn btn-outline-primary d-inline" onClick={signOut}>Sign out</button>
                    </div>
                </div>
            </div>
            <div className="container pt-4 pb-4 mb-5 ">
                <div className="row">
                    <div className="col-md-10 offset-md-1 shadow">
                        <ul className="nav nav-tabs mt-3 mb-3">
                            <li className="nav-item">
                                <button className={`nav-link custom-tab ${activeTab === 'tab1' ? 'active' : ''}`} onClick={() => setActiveTab('tab1')}>Level Infinite</button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link custom-tab ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => setActiveTab('tab2')}>DC Clients</button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link custom-tab ${activeTab === 'tab3' ? 'active' : ''}`} onClick={() => setActiveTab('tab3')}>Tencent</button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link custom-tab ${activeTab === 'tab4' ? 'active' : ''}`} onClick={() => setActiveTab('tab4')}>Foxtel Group</button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link custom-tab ${activeTab === 'tab5' ? 'active' : ''}`} onClick={() => setActiveTab('tab5')}>Forsyth Barr</button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link custom-tab ${activeTab === 'tab6' ? 'active' : ''}`} onClick={() => setActiveTab('tab6')}>Vistra</button>
                            </li>
                            <li className="nav-item ms-auto bg-warning bg-gradient rounded-pill">
                                <button className={`nav-link custom-tab ${activeTab === 'tab10' ? 'active' : ''}`} onClick={() => setActiveTab('tab10')}>Tool Box</button>
                            </li>
                        </ul>

                        <div className="tab-content">
                            {activeTab === 'tab1' && (
                                <div className="tab-pane active m-2">
                                    <div className="accordion" id="accordionPanelsStayOpen">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bricks me-2" viewBox="0 0 16 16">
                                                        <path d="M0 .5A.5.5 0 0 1 .5 0h15a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H2v-2H.5a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 .5 6H2V4H.5a.5.5 0 0 1-.5-.5zM3 4v2h4.5V4zm5.5 0v2H13V4zM3 10v2h4.5v-2zm5.5 0v2H13v-2zM1 1v2h3.5V1zm4.5 0v2h5V1zm6 0v2H15V1zM1 7v2h3.5V7zm4.5 0v2h5V7zm6 0v2H15V7zM1 13v2h3.5v-2zm4.5 0v2h5v-2zm6 0v2H15v-2z" />
                                                    </svg>
                                                    IP Address Whitelist
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                                <div className="accordion-body">
                                                    <p className="text-start text-primary"><strong>Usage:</strong> Use this tool to whitelist your IP address for Level Infinite Test Environment. You can only whitelist one IP address every time.</p>
                                                    <form onSubmit={(event) => whitelistIpApiCall(event, setApiResponse, setInputValueLiTestIPv4, setWhitelistShowModal, li_test_url, 'v4')}>
                                                        <div className="mb-3">
                                                            <label htmlFor="inputString" className="form-label">Your IPv4 Address</label>
                                                            <input type="text" className="form-control" id="inputString" placeholder="eg. 192.168.1.1" value={inputValueLiTestIPv4} onChange={e => setInputValueLiTestIPv4(e.target.value)} />
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary">Whitelist My IPv4</button>
                                                        </div>
                                                    </form>
                                                    <form onSubmit={(event) => whitelistIpApiCall(event, setApiResponse, setInputValueLiTestIPv6, setWhitelistShowModal, li_test_url, 'v6')}>
                                                        <div className="mb-3">
                                                            <label htmlFor="inputString" className="form-label">Your IPv6 Address</label>
                                                            <input type="text" className="form-control" id="inputString" placeholder="eg. 2001:0db8:85a3:0000:0000:8a2e:0370:7334" value={inputValueLiTestIPv6} onChange={e => setInputValueLiTestIPv6(e.target.value)} />
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary">Whitelist My IPv6</button>
                                                        </div>
                                                    </form>
                                                    <h4 className="text-center m-5 p-3 text-danger shadow"><strong><u>Level Infinite Production</u></strong> Environment is now <strong>Open to Public</strong>!!!</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )}
                            {activeTab === 'tab2' && (
                                <div className="tab-pane active m-2">
                                    <div>
                                        <div className="accordion" id="accordionPanelsStayOpen">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bricks  me-2" viewBox="0 0 16 16">
                                                            <path d="M0 .5A.5.5 0 0 1 .5 0h15a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H2v-2H.5a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 .5 6H2V4H.5a.5.5 0 0 1-.5-.5zM3 4v2h4.5V4zm5.5 0v2H13V4zM3 10v2h4.5v-2zm5.5 0v2H13v-2zM1 1v2h3.5V1zm4.5 0v2h5V1zm6 0v2H15V1zM1 7v2h3.5V7zm4.5 0v2h5V7zm6 0v2H15V7zM1 13v2h3.5v-2zm4.5 0v2h5v-2zm6 0v2H15v-2z" />
                                                        </svg>
                                                        IP Address Whitelist
                                                    </button>
                                                </h2>
                                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                                    <div className="accordion-body">
                                                        <p className="text-start text-primary"><strong>Usage:</strong> Use this tool to whitelist your IP address for DC Clients Test Environment. You can only whitelist one IP address every time.</p>
                                                        <form onSubmit={(event) => whitelistIpApiCall(event, setApiResponse, setInputValueDCClientIPv4, setWhitelistShowModal, dc_clients_url, 'v4')}>
                                                            <div className="mb-3">
                                                                <label htmlFor="inputString" className="form-label">Your IPv4 Address</label>
                                                                <input type="text" className="form-control" id="inputString" placeholder="eg. 192.168.1.1" value={inputValueDCClientIPv4} onChange={e => setInputValueDCClientIPv4(e.target.value)} />
                                                            </div>
                                                            <div className="d-flex justify-content-end">
                                                                <button type="submit" className="btn btn-primary">Whitelist My IPv4</button>
                                                            </div>
                                                        </form>
                                                        <form onSubmit={(event) => whitelistIpApiCall(event, setApiResponse, setInputValueDCClientIPv6, setWhitelistShowModal, dc_clients_url, 'v6')}>
                                                            <div className="mb-3">
                                                                <label htmlFor="inputString" className="form-label">Your IPv6 Address</label>
                                                                <input type="text" className="form-control" id="inputString" placeholder="eg. 2001:0db8:85a3:0000:0000:8a2e:0370:7334" value={inputValueDCClientIPv6} onChange={e => setInputValueDCClientIPv6(e.target.value)} />
                                                            </div>
                                                            <div className="d-flex justify-content-end">
                                                                <button type="submit" className="btn btn-primary">Whitelist My IPv6</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear-wide-connected  me-2" viewBox="0 0 16 16">
                                                            <path d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5m0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78zM5.048 3.967l-.087.065zm-.431.355A4.98 4.98 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8zm.344 7.646.087.065z" />
                                                        </svg>
                                                        ECS Service Manager
                                                    </button>
                                                </h2>
                                                <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                                    <div className="accordion-body">
                                                        <p className="text-start text-primary"><strong>Usage:</strong> Use this tool to turn on or shut down the ECS service by simply clicking the button for DC Clients Test Environment. You need to <span className='text-danger'><strong>WAIT</strong></span> a couple of minutes for the ECS task provisioning.</p>
                                                        <div className="container pt-4 pb-4 mb-5">
                                                            <div className="row">
                                                                <h5 className="col text-center">Project</h5><h5 className="col text-center">On/Off</h5><h5 className="col text-center">Action</h5>
                                                            </div>
                                                            {projects.map((project, index) => (
                                                                <div key={index} className="row align-items-center ">
                                                                    <div className="col text-center bg-info my-2 py-2 rounded-pill">
                                                                        <h5 className="d-inline text-white">{project.name}</h5>
                                                                    </div>
                                                                    <div className="col text-center">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={project.isChecked}
                                                                            disabled
                                                                            className="form-check-input"
                                                                        />
                                                                    </div>
                                                                    <div className="col text-center">
                                                                        <div className="d-grid gap-2 col-6 mx-auto">
                                                                            {project.isChecked ? (

                                                                                <button className="btn btn-danger" onClick={() => handleShutdown(project.svcName, setTaskNum, setSvcName, setSvcConfModal)}>
                                                                                    Shut Down
                                                                                </button>

                                                                            ) : (
                                                                                <button className="btn btn-primary" onClick={() => handleTurnOn(project.svcName, setTaskNum, setSvcName, setSvcConfModal)}>
                                                                                    Turn On
                                                                                </button>

                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-database-gear" className="bi bi-bricks me-2" viewBox="0 0 16 16">
                                                            <path d="M12.096 6.223A5 5 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.5 4.5 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-.813-.927Q8.378 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.6 4.6 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10q.393 0 .774-.024a4.5 4.5 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777M3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4" />
                                                            <path d="M11.886 9.46c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                                        </svg>
                                                        Download DC Client Database
                                                    </button>
                                                </h2>
                                                <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                                    <div className="accordion-body">
                                                        <p className="text-start text-primary"><strong>Usage:</strong> This tool can help you download the database dump file from our existing dc clients project. Select the database and click the <strong>Generate Download Link</strong> button. You will have the download link ready for you.</p>
                                                        <p className="text-start text-danger"><strong>Note:</strong> We also have database dump for production environment. However, considering the data security and compliance requirement, please talk to Harry if you really need them.</p>
                                                        <div className="container pt-4 pb-4 mb-5">
                                                            <div className="row p-2">
                                                                <div className="col d-flex justify-content-center align-items-center">
                                                                    <div className="dropdown m-3">
                                                                        <span className='me-5 text-secondary fs-4 fw-bolder'>Select:</span>
                                                                        <button className="btn-lg btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            {databaseName || 'Database'}
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                            {databases.map((database, index) => (
                                                                                <li key={index}>
                                                                                    <button className="dropdown-item" type="button" onClick={(event) => handleSelect(event, setDatabaseName)}>{database}</button>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <button className="btn btn-primary py-3" onClick={() => handleButtonClick(setDownloadLink, setS3DownloadModal, databaseName, dc_clients_s3_purl_api_url)} style={{ marginLeft: '10px' }}>
                                                                    <strong>Generate Download Link</strong>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'tab3' && (
                                <div className="tab-pane active m-2">
                                    <div className="accordion" id="accordionPanelsStayOpen">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tools me-2" viewBox="0 0 16 16">
                                                        <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3q0-.405-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708M3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026z" />
                                                    </svg>
                                                    In Development
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                                <div className="accordion-body">
                                                    <p className="text-start text-primary"><strong>Note:</strong> If you need any functions to be integrated into this portal, please let Harry know.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'tab4' && (
                                <div className="tab-pane active m-2">
                                    <div className="accordion" id="accordionPanelsStayOpen">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bricks me-2" viewBox="0 0 16 16">
                                                        <path d="M0 .5A.5.5 0 0 1 .5 0h15a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H2v-2H.5a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 .5 6H2V4H.5a.5.5 0 0 1-.5-.5zM3 4v2h4.5V4zm5.5 0v2H13V4zM3 10v2h4.5v-2zm5.5 0v2H13v-2zM1 1v2h3.5V1zm4.5 0v2h5V1zm6 0v2H15V1zM1 7v2h3.5V7zm4.5 0v2h5V7zm6 0v2H15V7zM1 13v2h3.5v-2zm4.5 0v2h5v-2zm6 0v2H15v-2z" />
                                                    </svg>
                                                    IP Address Whitelist
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                                <div className="accordion-body">
                                                    <p className="text-start text-primary"><strong>Usage:</strong> Use this tool to whitelist your IP address for Foxtel Group <strong>Test</strong> Environment. You can only whitelist one IP address every time.</p>
                                                    <form onSubmit={(event) => whitelistIpApiCall(event, setApiResponse, setInputValueFoxtelTestIPv4, setWhitelistShowModal, foxtel_test_url, 'v4')}>
                                                        <div className="mb-3">
                                                            <label htmlFor="inputString" className="form-label">Your IPv4 Address</label>
                                                            <input type="text" className="form-control" id="inputString" placeholder="eg. 192.168.1.1" value={inputValueFoxtelTestIPv4} onChange={e => setInputValueFoxtelTestIPv4(e.target.value)} />
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary">Whitelist My IPv4</button>
                                                        </div>
                                                    </form>
                                                    <form onSubmit={(event) => whitelistIpApiCall(event, setApiResponse, setInputValueFoxtelTestIPv6, setWhitelistShowModal, foxtel_test_url, 'v6')}>
                                                        <div className="mb-3">
                                                            <label htmlFor="inputString" className="form-label">Your IPv6 Address</label>
                                                            <input type="text" className="form-control" id="inputString" placeholder="eg. 2001:0db8:85a3:0000:0000:8a2e:0370:7334" value={inputValueFoxtelTestIPv6} onChange={e => setInputValueFoxtelTestIPv6(e.target.value)} />
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary">Whitelist My IPv6</button>
                                                        </div>
                                                    </form>
                                                    <h4 className="text-center m-5 p-3 text-danger shadow"><strong><u>Foxtel Production</u></strong> Environment is now <strong>Open to Public</strong>!!!</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )}
                            {activeTab === 'tab5' && (
                                <div className="tab-pane active m-2">
                                    <div className="accordion" id="accordionPanelsStayOpen">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bricks me-2" viewBox="0 0 16 16">
                                                        <path d="M0 .5A.5.5 0 0 1 .5 0h15a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H2v-2H.5a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 .5 6H2V4H.5a.5.5 0 0 1-.5-.5zM3 4v2h4.5V4zm5.5 0v2H13V4zM3 10v2h4.5v-2zm5.5 0v2H13v-2zM1 1v2h3.5V1zm4.5 0v2h5V1zm6 0v2H15V1zM1 7v2h3.5V7zm4.5 0v2h5V7zm6 0v2H15V7zM1 13v2h3.5v-2zm4.5 0v2h5v-2zm6 0v2H15v-2z" />
                                                    </svg>
                                                    IP Address Whitelist
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                                <div className="accordion-body">
                                                    <p className="text-start text-primary"><strong>Usage:</strong> Use this tool to whitelist your IP address for Forsyth Barr <strong><u>Test</u></strong> Environment. You can only whitelist one IP address every time.</p>
                                                    <form onSubmit={(event) => whitelistIpApiCall(event, setApiResponse, setInputValueFBTestIPv4, setWhitelistShowModal, fb_test_url, 'v4')}>
                                                        <div className="mb-3">
                                                            <label htmlFor="inputString" className="form-label">Your IPv4 Address</label>
                                                            <input type="text" className="form-control" id="inputString" placeholder="eg. 192.168.1.1" value={inputValueFBTestIPv4} onChange={e => setInputValueFBTestIPv4(e.target.value)} />
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary">Whitelist My IPv4</button>
                                                        </div>
                                                    </form>
                                                    <form onSubmit={(event) => whitelistIpApiCall(event, setApiResponse, setInputValueFBTestIPv6, setWhitelistShowModal, fb_test_url, 'v6')}>
                                                        <div className="mb-3">
                                                            <label htmlFor="inputString" className="form-label">Your IPv6 Address</label>
                                                            <input type="text" className="form-control" id="inputString" placeholder="eg. 2001:0db8:85a3:0000:0000:8a2e:0370:7334" value={inputValueFBTestIPv6} onChange={e => setInputValueFBTestIPv6(e.target.value)} />
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary">Whitelist My IPv6</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                                <div className="accordion-body">
                                                    <p className="text-start text-primary"><strong>Usage:</strong> Use this tool to whitelist your IP address for Forsyth Barr <strong><u>UAT</u></strong> Environment. You can only whitelist one IP address every time.</p>
                                                    <form onSubmit={(event) => whitelistIpApiCall(event, setApiResponse, setInputValueFBUatIPv4, setWhitelistShowModal, fb_uat_url, 'v4')}>
                                                        <div className="mb-3">
                                                            <label htmlFor="inputString" className="form-label">Your IPv4 Address</label>
                                                            <input type="text" className="form-control" id="inputString" placeholder="eg. 192.168.1.1" value={inputValueFBUatIPv4} onChange={e => setInputValueFBUatIPv4(e.target.value)} />
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary">Whitelist My IPv4</button>
                                                        </div>
                                                    </form>
                                                    <form onSubmit={(event) => whitelistIpApiCall(event, setApiResponse, setInputValueFBUatIPv6, setWhitelistShowModal, fb_uat_url, 'v6')}>
                                                        <div className="mb-3">
                                                            <label htmlFor="inputString" className="form-label">Your IPv6 Address</label>
                                                            <input type="text" className="form-control" id="inputString" placeholder="eg. 2001:0db8:85a3:0000:0000:8a2e:0370:7334" value={inputValueFBUatIPv6} onChange={e => setInputValueFBUatIPv6(e.target.value)} />
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary">Whitelist My IPv6</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )}

                            {activeTab === 'tab6' && (
                                <div className="tab-pane active m-2">
                                    <div className="accordion" id="accordionPanelsStayOpen">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bricks me-2" viewBox="0 0 16 16">
                                                        <path d="M0 .5A.5.5 0 0 1 .5 0h15a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H2v-2H.5a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 .5 6H2V4H.5a.5.5 0 0 1-.5-.5zM3 4v2h4.5V4zm5.5 0v2H13V4zM3 10v2h4.5v-2zm5.5 0v2H13v-2zM1 1v2h3.5V1zm4.5 0v2h5V1zm6 0v2H15V1zM1 7v2h3.5V7zm4.5 0v2h5V7zm6 0v2H15V7zM1 13v2h3.5v-2zm4.5 0v2h5v-2zm6 0v2H15v-2z" />
                                                    </svg>
                                                    IP Address Whitelist
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                                <div className="accordion-body">
                                                    <p className="text-start text-primary"><strong>Usage:</strong> Use this tool to whitelist your IP address for Vistra Onboarding <strong><u>Dev</u></strong> Environment. You can only whitelist one IP address every time.</p>
                                                    <form onSubmit={(event) => whitelistIpApiCall(event, setApiResponse, setInputValueVistraObDevIPv4, setWhitelistShowModal, vistra_ob_dev_url, 'v4')}>
                                                        <div className="mb-3">
                                                            <label htmlFor="inputString" className="form-label">Your IPv4 Address</label>
                                                            <input type="text" className="form-control" id="inputString" placeholder="eg. 192.168.1.1" value={inputValueVistraObDevIPv4} onChange={e => setInputValueVistraObDevIPv4(e.target.value)} />
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary">Whitelist My IPv4</button>
                                                        </div>
                                                    </form>
                                                    <form onSubmit={(event) => whitelistIpApiCall(event, setApiResponse, setInputValueVistraObDevIPv6, setWhitelistShowModal, vistra_ob_dev_url, 'v6')}>
                                                        <div className="mb-3">
                                                            <label htmlFor="inputString" className="form-label">Your IPv6 Address</label>
                                                            <input type="text" className="form-control" id="inputString" placeholder="eg. 2001:0db8:85a3:0000:0000:8a2e:0370:7334" value={inputValueVistraObDevIPv6} onChange={e => setInputValueVistraObDevIPv6(e.target.value)} />
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary">Whitelist My IPv6</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                                <div className="accordion-body">
                                                    <p className="text-start text-primary"><strong>Usage:</strong> Use this tool to whitelist your IP address for Vistra Onboarding <strong><u>UAT</u></strong> Environment. You can only whitelist one IP address every time.</p>
                                                    <form onSubmit={(event) => whitelistIpApiCall(event, setApiResponse, setInputValueVistraObUatIPv4, setWhitelistShowModal, vistra_ob_uat_url, 'v4')}>
                                                        <div className="mb-3">
                                                            <label htmlFor="inputString" className="form-label">Your IPv4 Address</label>
                                                            <input type="text" className="form-control" id="inputString" placeholder="eg. 192.168.1.1" value={inputValueVistraObUatIPv4} onChange={e => setInputValueVistraObUatIPv4(e.target.value)} />
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary">Whitelist My IPv4</button>
                                                        </div>
                                                    </form>
                                                    <form onSubmit={(event) => whitelistIpApiCall(event, setApiResponse, setInputValueVistraObUatIPv6, setWhitelistShowModal, vistra_ob_uat_url, 'v6')}>
                                                        <div className="mb-3">
                                                            <label htmlFor="inputString" className="form-label">Your IPv6 Address</label>
                                                            <input type="text" className="form-control" id="inputString" placeholder="eg. 2001:0db8:85a3:0000:0000:8a2e:0370:7334" value={inputValueVistraObUatIPv6} onChange={e => setInputValueVistraObUatIPv6(e.target.value)} />
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary">Whitelist My IPv6</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}


                            {activeTab === 'tab10' && (
                                <div className="tab-pane active m-2">
                                    <div className="container pt-4 pb-4 ">
                                        <div className="d-flex justify-content-start align-items-center">
                                            <header className="App-header">
                                                <button className="btn btn-lg btn-secondary p-3 mx-2" onClick={handleShowIP} >Show My IP Address</button>
                                            </header>
                                        </div>
                                    </div>
                                    <div className="container pt-4 pb-4 ">
                                        <p className=' fw-bolder font-monospace text-danger'>More tools? Tell us your requirement!</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <ShowModal
                isOpen={whitelistShowModal}
                onClose={() => setWhitelistShowModal(false)}
                title="Whitelist IP"
                message={`<p>${apiResponse}</p>`}
            />

            <ShowModal
                isOpen={s3DownloadModal}
                onClose={() => handleClose(setS3DownloadModal)}
                title="Download DB"
                message={`Click <a href=${downloadLink} download>Here</a> to Download.`}
            />

            <ShowModal
                isOpen={myIPShowModal}
                onClose={() => handleClose(setMyIPShowModal)}
                title="Your IP address is"
                message={`<p>${apiResponse}</p>`}
            />


            <ConfirmModal
                isOpen={svcConfModal}
                taskNum={taskNum}
                svcName={svcName}
                onClose={() => setSvcConfModal(false)}
                onConfirm={() =>
                    toggleService(
                        svcName,
                        taskNum,
                        projects,
                        setApiResponse,
                        setSvcConfModal,
                        dc_clients_ecs_api_url
                    )
                }
            />

            <footer className="footer">
                <p>Dave Clark © All Right Reserved</p>
            </footer>
        </main>


    );
}