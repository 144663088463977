// Update ECS Status For DC Clients
import { fetchAuthSession } from 'aws-amplify/auth';

const fetchStatus = async (svcName, projects, setApiResponse, setConfirmModal, ecsApiUrl) => {
    try {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const response = await fetch(`${ecsApiUrl}?svcname=${svcName}`, {
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        });
        if (response.status === 200) {
            const data = await response.json();
            const project = projects.find(p => p.svcName === svcName);
            if (project) {
                project.setIsChecked(data.running_count > 0);
            }
            setConfirmModal(false); // Hide modal after successful API call
        } else {
            setApiResponse('Failed to fetch ECS Service status');
            setConfirmModal(false); // Hide modal on failure
        }
    } catch (error) {
        console.error(`Error fetching status for ${svcName}:`, error);
        setApiResponse('Failed to fetch ECS Service status');
        setConfirmModal(false); // Hide modal on failure
    }
};

const toggleService = async (svcName, tasknum, projects, setApiResponse, setConfirmModal, ecsApiUrl) => {
    try {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const response = await fetch(`${ecsApiUrl}?svcname=${svcName}&tasknum=${tasknum}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        });
        if (response.status === 200) {
            // If successful, re-fetch status and update checkbox
            fetchStatus(svcName, projects, setApiResponse, setConfirmModal, ecsApiUrl);
        } else {
            setApiResponse('Failed to fetch ECS Service status');
            setConfirmModal(false); // Hide modal on failure
        }
    } catch (error) {
        console.error(`Error calling API for ${svcName}:`, error);
        setApiResponse('Oops...You failed to change the ECS service status. I know you feel depressed but I have an idea - Talk to Harry.');
        setConfirmModal(false); // Hide modal on failure
    }
};

const handleTurnOn = (svcName, setTaskNum, setSvcName, setConfirmModal) => {
    setTaskNum(1); // Set tasknum to turn on
    setSvcName(svcName);
    setConfirmModal(true);
};

const handleShutdown = (svcName, setTaskNum, setSvcName, setConfirmModal) => {
    setTaskNum(0); // Set tasknum to shut down
    setSvcName(svcName);
    setConfirmModal(true);
};

export { fetchStatus, toggleService, handleTurnOn, handleShutdown };
