import { Heading, useTheme } from "@aws-amplify/ui-react";

export function SignInHeader() {
    const { tokens } = useTheme();

    return (
        <div style={{ textAlign: 'center', padding: `${tokens.space.xl}` }}>
            <Heading level={3} style={{ marginBottom: '20px' }}>
                Tech Admin Portal
            </Heading>
            <div style={{ textAlign: 'left', marginTop: 0 }}>
                <Heading level={3} style={{ fontSize: '1.5em', paddingTop: 0 }}>
                    Sign in to your Account
                </Heading>
            </div>
        </div>
    );
}
